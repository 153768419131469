import React, { useState, useCallback, useEffect } from 'react'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'

const ImageGrid = props => {
  const photos = props.ImageTab.map(element => {
    return {
      src: element.thumb.src,
      width: element.thumb.aspectRatio,
      height: 1,
    }
  })
  const fullPhotos = props.ImageTab.map(element => {
    return {
      src: element.full.src,
      width: element.full.aspectRatio,
      height: 1,
    }
  })
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  useEffect(() => {
    photos.map(photo => {
      photo.loading = 'lazy'
      return photo
    })
    fullPhotos.map(photo => {
      photo.loading = 'lazy'
      return photo
    })
  }, [])

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={fullPhotos.map(currentImage => ({
                ...currentImage,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default ImageGrid
