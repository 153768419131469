import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import ImageGrid from './ImageGrid'
import GalleryMenu from './GalleryMenu'
import GalleryImageSupplier from './GalleryImageSupplier'

const GalleryContainer = () => {
  const [value, valueHandle] = useState('aquarelles')

  const images = GalleryImageSupplier()
  let imagesFiltered = []

  const styleContainer = {
    color: 'black',
  }

  images.map(obj => {
    if (obj.node.relativeDirectory.includes(value)) {
      imagesFiltered.push(obj.node.childImageSharp)
    }
  })

  const handleChange = event => {
    valueHandle(event.target.value)
  }

  return (
    <Container
      fluid
      className="d-flex flex-column overflow-auto mh-100"
      style={styleContainer}
    >
      <Row style={{ backgroundColor: 'white', paddingTop: 'calc(1rem + 1.5vw)' }}>
        <Col>
          <GalleryMenu value={value} handleChange={handleChange} />
        </Col>
      </Row>

      {imagesFiltered ? <ImageGrid ImageTab={imagesFiltered} /> : <div></div>}
    </Container>
  )
}

export default GalleryContainer
