import React from 'react'
import Form from 'react-bootstrap/Form'

const GalleryMenu = props => {
  return (
    <Form  >
      <Form.Group key={`custom-inline-radio`} className="mb-3">
        <Form.Check
          custom
          name="categoriePeinture"
          inline
          label="Aquarelles"
          value="aquarelles"
          type="radio"
          id={`custom-inline-radio-1`}
          checked={props.value === 'aquarelles'}
          onChange={props.handleChange}
        />
        <Form.Check
          custom
          inline
          name="categoriePeinture"
          value="croquis"
          label="Croquis"
          type="radio"
          id={`custom-inline-radio-2`}
          checked={props.value === 'croquis'}
          onChange={props.handleChange}
        />

        <Form.Check
          custom
          inline
          label="Fusains"
          type="radio"
          name="categoriePeinture"
          value="fusains"
          id={`custom-inline-radio-3`}
          checked={props.value === 'fusains'}
          onChange={props.handleChange}
        />
        <Form.Check
          custom
          inline
          label="Huiles"
          type="radio"
          name="categoriePeinture"
          value="huiles"
          id={`custom-inline-radio-4`}
          checked={props.value === 'huiles'}
          onChange={props.handleChange}
        />
        <Form.Check
          custom
          inline
          label="Pastels"
          type="radio"
          name="categoriePeinture"
          value="pastels"
          id={`custom-inline-radio-5`}
          checked={props.value === 'pastels'}
          onChange={props.handleChange}
        />

        <Form.Check
          custom
          inline
          label="Temperas"
          type="radio"
          name="categoriePeinture"
          value="temperas"
          id={`custom-inline-radio-6`}
          checked={props.value === 'temperas'}
          onChange={props.handleChange}
        />
      </Form.Group>
    </Form>
  )
}

export default GalleryMenu
