import { graphql, useStaticQuery } from "gatsby";

/**
 * Uses GatsbyImage and GraphQL to return all images.
 * @see https://www.orangejellyfish.com/blog/a-comprehensive-guide-to-images-in-gatsby/
 */
const GalleryImageSupplier = (subFolder) => {

  // Don't forget to set the size of your image in
  // fluid(maxWidth: 300, quality: 100) {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: {
        extension: {regex: "/(jpg)|(jpeg)|(png)|(webp)/"}, 
        sourceInstanceName: {eq: "images"}}) 
      {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxHeight: 400, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
              full: fixed(height: 700, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
            relativeDirectory
          }
        }
      }
    }`);

  return ( allFile.edges );
};

export default GalleryImageSupplier;